import {
    AppBar,
    Button,
    createStyles,
    makeStyles,
    Tab,
    Tabs,
    Toolbar,
    Theme,
    Box, SwipeableDrawer, Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Routes} from "../App";
import logo from "../images/Header/Logo.webp";
import MenuIcon from '@material-ui/icons/Menu';
import {BASE_URL, Content, ItemEntry} from "../views/admin/Admin";
import axios from "axios";
import {getGroupedGroups} from "../utils/ContentsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            objectFit: "contain",
            height: "9vh",
            padding: "-2vh"
        },
        menuButton: {
            width: "100%",
            height: "100%"
        },
        navigation: {
            position: "relative",
            marginLeft: "auto",
            marginRight: "0",
            alignItems: "right",
            color: theme.palette.secondary.main,
        },
        drawerTabs: {
            paddingLeft: "10vw",
            paddingRight: "10vw",
            width: "100%",
            textAlign: "right"
        },
        drawerTab: {
            marginTop: theme.spacing(5),
        },
        drawer: {
            color: theme.palette.secondary.main,
            background: theme.palette.primary.main,
        },
        drawerTypography: {
        }
    }),
);

export default function Header(routes: Routes) {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setOpenDrawer(open);
    };

    const generateTabs = (orientation: "horizontal" | "vertical",
                          component: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body",
                          variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2",
                          tabsClass: string,
                          tabClass?: string,
                          typographyClass?: string) => (
        <Tabs value={routes.currentRoute} className={tabsClass} orientation={orientation}>
            <Tab label={<Typography component={component} className={typographyClass}
                                    variant={variant}>Home</Typography>}
                 value={routes.homeRoute}
                 className={tabClass}
                 onClick={() => toggleDrawer(false)} component={Link}
                 to={routes.homeRoute}/>
            <Tab label={<Typography component={component} className={typographyClass}
                                    variant={variant}>Galerie</Typography>}
                 value={routes.galleryRoute} onClick={() => toggleDrawer(false)} component={Link}
                 className={tabClass}
                 to={routes.galleryRoute}/>
            <Tab label={<Typography component={component} className={typographyClass}
                                    variant={variant}>Kontakt</Typography>}
                 value={routes.contactRoute} onClick={() => toggleDrawer(false)} component={Link}
                 className={tabClass}
                 to={routes.contactRoute}/>
            <Tab label={<Typography component={component} className={typographyClass}
                                    variant={variant}>Presse</Typography>}
                 value={routes.pressRoute} onClick={() => toggleDrawer(false)} component={Link}
                 className={tabClass}
                 to={routes.pressRoute}/>
            <Tab label={<Typography component={component} className={typographyClass}
                                    variant={variant} noWrap={true}>Über uns</Typography>}
                 value={routes.aboutUsRoute} onClick={() => toggleDrawer(false)} component={Link}
                 className={tabClass}
                 to={routes.aboutUsRoute}/>
        </Tabs>
    )
    const verticalTabs = generateTabs("vertical", "h4", "h4", classes.drawerTabs, classes.drawerTab, classes.drawerTypography)
    const horizontalTab = generateTabs("horizontal", "body", "body1", classes.navigation)
    const drawer = (<>
        <Box className={classes.navigation}
             display={{xs: 'none', sm: 'none', md: 'none', lg: 'block'}}>{horizontalTab}</Box>
        <Box className={classes.navigation}
             display={{xs: 'block', sm: 'block', md: 'block', lg: 'none'}}>
            <Button className={classes.menuButton} onClick={() => toggleDrawer(true)}><MenuIcon
                color={"secondary"}/></Button>
        </Box>
    </>)

    return <><AppBar
        position="sticky">
        <Toolbar>
            <img src={logo} alt="Wiest" className={classes.logo}/>
            {drawer}
        </Toolbar>
    </AppBar>
        <SwipeableDrawer classes={{paper: classes.drawer}} variant="temporary" open={openDrawer}
                         anchor="right" onClose={() => toggleDrawer(false)}
                         onOpen={() => toggleDrawer(true)}>
            <Toolbar><Button className={classes.menuButton} onClick={() => toggleDrawer(false)}><MenuIcon
                color={"secondary"}/></Button></Toolbar>
            {verticalTabs}</SwipeableDrawer>
    </>
}
