import {Container, Grid} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {BASE_URL, Content, ItemEntry} from "./admin/Admin";
import axios from "axios";
import {getGroupedGroups, getTypedItems} from "../utils/ContentsHelper";
import {getLoadingScreen, useStyles as AppUseStyles} from "../App";
import contentContentBackgroundImage from "../images/Home/background.webp";
import {Helmet} from "react-helmet";

export type ContactContents = {
    contents: ItemEntry[][],
    contentsNoPrio: ItemEntry[][],
}

const useStyles = makeStyles((theme: Theme) => ({
    contactContainer: {
        padding: theme.spacing(3),
    },
    mapContainer: {
        padding: theme.spacing(3),
        height: "100vh"
    },
    map: {
        border: 0
    },
    gridItem: {
        width: "100%",
    },
    title: {
        textAlign: "center",
    },
    address: {
        textAlign: "center",
    },
    leftContacts: {
        textAlign: "right"
    },
    rightContacts: {
        textAlign: "left",
        color: theme.palette.primary.main
    },
    card: {
        margin: theme.spacing(1),
        height: "100%",
        backgroundImage: `url(${contentContentBackgroundImage})`,
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main
    },
}));

export default function Contact(contactContents: ContactContents) {
    const classes = useStyles();
    const {contents, contentsNoPrio} = contactContents;

    const appClasses = AppUseStyles()
    const loadingScreen = getLoadingScreen(appClasses)

    if (contents.length <= 0) {
        return (<div>
            {loadingScreen}
        </div>)
    }

    return (<div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Kontakt - Schreinerei Wiest</title>
            <meta
                name="description"
                content="Kontaktieren sie uns und lassen sie sich professionell beraten"
            />
        </Helmet>
        <Container className={classes.contactContainer}>
            <Grid container>
                <Grid item md={4} spacing={1} className={classes.gridItem}>
                        {getTypedItems(4, contents, classes.title).map((item) => {
                            return (item)
                        })}
                        <Grid container spacing={1} className={classes.gridItem}>
                            <Grid item xs={5}>
                                {getTypedItems(7, contents, classes.leftContacts).map((item) => {
                                    return (item)
                                })}
                            </Grid>
                            <Grid item xs={7}>
                                {getTypedItems(1, contents, classes.rightContacts).map((item) => {
                                    return (item)
                                })}
                            </Grid>
                        </Grid>
                </Grid>
                <Grid item md={4} spacing={3} className={classes.gridItem}>
                    {getTypedItems(5, contents, classes.title).map((item) => {
                        return (item)
                    })}
                    {getTypedItems(2, contents, classes.address).map((item) => {
                        return (item)
                    })}
                </Grid>
                <Grid item md={4} spacing={3} className={classes.gridItem}>
                    {getTypedItems(6, contents, classes.title).map((item) => {
                        return (item)
                    })}
                    {getTypedItems(3, contents, classes.address).map((item) => {
                        return (item)
                    })}
                </Grid>
            </Grid>
        </Container>
        <Container className={classes.mapContainer}>
            <iframe className={classes.map}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2661.3950499778525!2d10.114496850946416!3d48.16046695744385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479be431ad3589b5%3A0x200cb4a01cbbc2fa!2sSchreinerei%20Wiest!5e0!3m2!1sde!2sde!4v1614519372519!5m2!1sde!2sde"
                    width="100%" height="100%" allowFullScreen={true} loading="lazy"/>
        </Container>
    </div>)
}
