import {Content, ItemEntry} from "./Admin"
import {
    Button,
    createStyles,
    Divider,
    FormGroup,
    Grid,
    TextField,
    Select,
    MenuItem, Typography, CircularProgress, Fab, Checkbox, FormControlLabel,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import React, {ChangeEvent, useEffect, useState} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import axios from "axios";
import arrayMove from 'array-move';
import {getGroupedGroups} from "../../utils/ContentsHelper"
import {DragDropContext, Droppable, Draggable, DropResult} from "react-beautiful-dnd";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ImageUploader from 'react-images-upload';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SaveIcon from '@material-ui/icons/Save';

// @ts-ignore
import styled from '@emotion/styled'
import {useCookies} from "react-cookie";
import {Snackbar} from "@material-ui/core";
import {HotKeys} from "react-hotkeys";

type SortableItem = {
    item: ItemEntry
    itemIndex: number,
    groupIndex: number
}

type SortableGroupItem = {
    items: ItemEntry[],
    groupIndex: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: theme.palette.success.main,
            // backgroundColor: theme.palette.secondary.main
        },
        dragHandle: {
            margin: "1em"
        },
        group: {
            border: "0.1em solid #da6d42",
            margin: "0.5em"
        },
        image: {
            objectFit: "cover",
            height: "25vh",
        },
        imageDragButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
        },
        imageDragDropButton: {
            backgroundColor: theme.palette.primary.main,
            color: "red"
        },
        select: {
            margin: "1em",
        },
        selectMenuItem: {
            zIndex: 20000,
            backgroundColor: theme.palette.background.default,
            "&:hover": {
                zIndex: 20000,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        addButton: {
            margin: "1em"
        },
        deleteButton: {
            margin: "1em"
        },
        draggableItem: {
            height: "15vh"
        },
        textFieldFullWidth: {
            width: "90%",
            height: "80%",
            margin: "1em"
        }
    }),
);

const BASE_64 = "data:image/gif;base64,";

export default function ContentConfig(items: Content) {
    const classes = useStyles();
    const [diffItems, setDiffItems] = useState<ItemEntry[]>([])
    const [saved, setSaved] = useState<number>(0)
    const [sortableItems, setSortableItems] = useState<ItemEntry[][]>([])
    const [currentGroupIndex, setCurrentGroupIndex] = useState<number>()
    const [currentItemIndex, setCurrentItemIndex] = useState<number>()
    const [deleted, setDeleted] = useState<number[]>([])
    const [selectionStart, setSelectionStart] = useState<number | null>(0)
    const [selectionEnd, setSelectionEnd] = useState<number | null>(0)

    const [sessionToken, , removeSessionToken] = useCookies(['token']);

    // // Run only once
    useEffect(() => {
        setSortableItems(getGroupedGroups(items.content))
    }, [items])

    if (sortableItems.length === 0) return (
        <div></div>)

    const onSave = async () => {
        setSaved(4)
        if (diffItems.length === 0 && deleted.length === 0) {
            setSaved(2)
            return;
        }
        const tmpDiffItems: ItemEntry[] = diffItems.slice()
        setDiffItems([])
        try {
            if (diffItems.length > 0) {
                await axios.patch(items.baseUrl + "/patch/" + items.typeUrl, {
                    items: tmpDiffItems
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionToken.token
                    },
                })
            }
            if (deleted.length > 0) {
                await axios.post(items.baseUrl + "/delete/" + items.typeUrl, {
                    items: deleted,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionToken.token
                    }
                })
            }
            setSaved(1)
        } catch (error) {
            setSaved(3)
            setDiffItems(tmpDiffItems)
            console.error(error)
        }
        setTimeout(function () {
            window.location.reload()
        }, 5000);
    }

    const onLogout = () => {
        removeSessionToken('token')
        window.location.reload()
    }

    const onContentChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, item: SortableItem) => {
        const newSortableGroupItems: ItemEntry[][] = sortableItems.slice();
        const enteredText: string = event.target.value
        const newSortableItems = newSortableGroupItems[item.groupIndex];
        if (newSortableItems[item.itemIndex].content !== enteredText) {
            setCurrentGroupIndex(item.groupIndex)
            setCurrentItemIndex(item.itemIndex)
            setSelectionStart(event.target.selectionStart)
            setSelectionEnd(event.target.selectionEnd)

            newSortableItems[item.itemIndex].content = enteredText;
            newSortableGroupItems[item.groupIndex] = newSortableItems;

            setSortableItems(newSortableGroupItems);

            const itemEntry = newSortableItems[item.itemIndex];

            const newDiffItems: ItemEntry[] = diffItems.slice();
            const newItem: ItemEntry = {
                id: itemEntry.id,
                name: itemEntry.name,
                content: enteredText,
                group_id: itemEntry.group_id,
                type: itemEntry.type,
                group_order: itemEntry.group_order,
                priority: itemEntry.priority
            }
            checkDiffItemExists(itemEntry, newItem, newDiffItems);
            setDiffItems(newDiffItems)
        }
    }

    const checkDiffItemExists = (itemEntry: ItemEntry, newItem: ItemEntry, newDiffItems: ItemEntry[]) => {
        let containsIndex: number = -1;
        for (let i = 0; i < newDiffItems.length; i++) {
            if (newDiffItems[i].id === itemEntry.id) {
                containsIndex = i
            }
        }
        if (containsIndex >= 0) {
            newDiffItems[containsIndex] = newItem;
        } else {
            newDiffItems.push(newItem);
        }
    }

    const onTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, item: SortableItem) => {
        const newSortableGroupItems: ItemEntry[][] = sortableItems.slice();
        const newSortableItems = newSortableGroupItems[item.groupIndex];
        const enteredValue = event.target.value
        console.log(enteredValue)
        if (newSortableItems[item.itemIndex].type !== enteredValue) {
            newSortableItems[item.itemIndex].type = enteredValue as number;
            newSortableGroupItems[item.groupIndex] = newSortableItems;

            setSortableItems(newSortableGroupItems);

            const itemEntry = newSortableItems[item.itemIndex];

            const newDiffItems: ItemEntry[] = diffItems.slice();
            const newItem: ItemEntry = {
                id: itemEntry.id,
                name: itemEntry.name,
                content: itemEntry.content,
                group_id: itemEntry.group_id,
                type: enteredValue as number,
                group_order: itemEntry.group_order,
                priority: itemEntry.priority
            }
            checkDiffItemExists(itemEntry, newItem, newDiffItems);
            console.log(newDiffItems.length)
            setDiffItems(newDiffItems)
        }
    }

    const onPriorityChange = (event: React.ChangeEvent<{ name?: string; checked: unknown }>, item: SortableItem) => {
        const newSortableGroupItems: ItemEntry[][] = sortableItems.slice();
        const newSortableItems = newSortableGroupItems[item.groupIndex];
        const enteredValue = event.target.checked;
        console.log(enteredValue)
        if (newSortableItems[item.itemIndex].priority !== enteredValue) {
            newSortableItems[item.itemIndex].priority = enteredValue as boolean;
            newSortableGroupItems[item.groupIndex] = newSortableItems;

            setSortableItems(newSortableGroupItems);

            const itemEntry = newSortableItems[item.itemIndex];

            const newDiffItems: ItemEntry[] = diffItems.slice();
            const newItem: ItemEntry = {
                id: itemEntry.id,
                name: itemEntry.name,
                content: itemEntry.content,
                group_id: itemEntry.group_id,
                type: itemEntry.type,
                group_order: itemEntry.group_order,
                priority: enteredValue as boolean,
            }
            checkDiffItemExists(itemEntry, newItem, newDiffItems);
            console.log(newDiffItems.length)
            setDiffItems(newDiffItems)
        }
    }

    function getBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async function onImageDrop(pictures: File[], item: SortableItem) {
        if (pictures.length <= 0) return;
        console.log("OnImageChange")
        const newSortableGroupItems: ItemEntry[][] = sortableItems.slice();
        const newSortableItems = newSortableGroupItems[item.groupIndex];
        const enteredValue = await getBase64(pictures[0]) as string;
        console.log("Base64 Image:" + enteredValue);
        if (enteredValue === undefined) return;
        console.log(item.itemIndex)
        console.log(newSortableItems)
        if (newSortableItems[item.itemIndex].content !== enteredValue) {
            newSortableItems[item.itemIndex].content = enteredValue;
            console.log(newSortableItems)
            newSortableGroupItems[item.groupIndex] = newSortableItems;

            setSortableItems(newSortableGroupItems);

            const itemEntry = newSortableItems[item.itemIndex];

            const newDiffItems: ItemEntry[] = diffItems.slice();
            const newItem: ItemEntry = {
                id: itemEntry.id,
                name: itemEntry.name,
                content: enteredValue,
                group_id: itemEntry.group_id,
                type: itemEntry.type,
                group_order: itemEntry.group_order,
                priority: itemEntry.priority
            }
            checkDiffItemExists(itemEntry, newItem, newDiffItems);
            setDiffItems(newDiffItems)
        }
    }

    const onDragEnd = (result: DropResult, group: ItemEntry[], groupIndex: number) => {
        if (!result.destination) {
            return;
        }

        const destinationIndex = result.destination.index
        const sourceIndex = result.source.index
        if (destinationIndex === sourceIndex)
            return;


        let tmpSortableItems: ItemEntry[][] = sortableItems.slice()
        let currentList: ItemEntry[] = tmpSortableItems[groupIndex]
        currentList[sourceIndex].group_order = destinationIndex
        currentList[destinationIndex].group_order = sourceIndex
        currentList = arrayMove(currentList, sourceIndex, destinationIndex)
        for (let i = 0; i < currentList.length; i++) {
            currentList[i].group_order = i;
        }
        tmpSortableItems[groupIndex] = currentList;
        setSortableItems(tmpSortableItems)


        const newDiffItems: ItemEntry[] = diffItems.slice();
        for (let i = 0; i < currentList.length; i++) {
            newDiffItems.push(currentList[i]);
        }
        setDiffItems(newDiffItems)
        setSortableItems(tmpSortableItems);
    }

    async function onAddButtonClick(event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
                                    group: ItemEntry[], groupIndex: number) {
        const newSortableGroupItems: ItemEntry[][] = sortableItems.slice();
        const newGroup: ItemEntry[] = group.slice();
        if (group.length <= 0) return
        const itemEntry = group[group.length - 1];
        const newItem: ItemEntry = {
            id: -Date.now(),
            content: "Neuer Eintrag",
            type: 1,
            group_order: itemEntry.group_order + 1,
            name: itemEntry.name,
            group_id: itemEntry.group_id,
            priority: itemEntry.priority
        }
        newGroup.push(newItem)

        const newDiffItems: ItemEntry[] = diffItems.slice();
        checkDiffItemExists(itemEntry, newItem, newDiffItems);
        setDiffItems(newDiffItems)
        newSortableGroupItems[groupIndex] = newGroup;
        setSortableItems(newSortableGroupItems);
    }

    function onDeleteButtonClick(event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
                                 item: SortableItem) {
        const newSortableGroupItems: ItemEntry[][] = sortableItems.slice();
        const newSortableItems = newSortableGroupItems[item.groupIndex];

        const itemEntry = newSortableItems[item.itemIndex];
        newSortableItems.splice(item.itemIndex, 1);
        newSortableGroupItems[item.groupIndex] = newSortableItems;

        setSortableItems(newSortableGroupItems);


        const newDeleteItems: number[] = deleted.slice();
        newDeleteItems.push(itemEntry.id)
        setDeleted(newDeleteItems)
    }

    function onFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (selectionStart === null || selectionEnd === null) {
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
        } else {
            e.currentTarget.setSelectionRange(selectionStart, selectionEnd);
        }
    }


    function ContentTextField(sortableItem: SortableItem) {
        const id: string = "group-" + sortableItem.groupIndex + "-item-" + sortableItem.itemIndex
        // Check if Image
        if (sortableItem.item.type === 11 || sortableItem.item.type === 12) {
            return (
                <div>
                    <Grid container>
                        <Grid item xs={4}>
                            <img key={"preview-img-" + sortableItem.groupIndex + "-" + sortableItem.itemIndex}
                                 className={classes.image} src={sortableItem.item.content} alt={"Image"}/>
                        </Grid>
                        <Grid item xs={8}>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Bild auswählen'
                                onChange={async (event) => await onImageDrop(event, sortableItem)}
                                imgExtension={['.jpg', '.gif', '.png', '.gif', '.webp']}
                                withPreview={true}
                                singleImage={true}
                            />
                        </Grid>
                    </Grid>
                </div>)
        } else if (currentGroupIndex === sortableItem.groupIndex && currentItemIndex === sortableItem.itemIndex) {
            return (<TextField
                id={id}
                autoFocus={true}
                onFocus={(e) => onFocus(e)}
                variant="outlined"
                multiline
                fullWidth

                className={classes.textFieldFullWidth}
                label={sortableItem.item.name} value={sortableItem.item.content}
                onChange={(event) => onContentChange(event, sortableItem)}/>)
        } else {
            return (<TextField
                id={id}
                variant="outlined"
                multiline
                fullWidth
                className={classes.textFieldFullWidth}
                label={sortableItem.item.name} value={sortableItem.item.content}
                onChange={(event) => onContentChange(event, sortableItem)}/>)
        }
    }

    // @ts-ignore
    function DraggableItem(sortableItem: SortableItem) {
        return (
            <Draggable draggableId={sortableItem.item.content} index={sortableItem.itemIndex}>
                {provided => (
                    <div
                        className={classes.group}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Grid container>
                            <Grid item xs={1}>
                                <DragHandleIcon className={classes.dragHandle}/>
                            </Grid>
                            <Grid item xs={8}>
                                <ContentTextField item={sortableItem.item} itemIndex={sortableItem.itemIndex}
                                                  groupIndex={sortableItem.groupIndex}/>
                            </Grid>
                            <Grid item xs={1}>
                                <Select
                                    className={classes.select}
                                    labelId="Typ"
                                    id={"select-" + sortableItem.itemIndex}
                                    value={sortableItem.item.type}
                                    onChange={(event) => onTypeChange(event, sortableItem)}
                                >
                                    {items.types.map((value) => {
                                        return (<MenuItem className={classes.selectMenuItem}
                                                          value={value.id}>{value.name}</MenuItem>)
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    value="top"
                                    id={"checkbox-" + sortableItem.itemIndex}
                                    control={<Checkbox color="primary"
                                                       checked={sortableItem.item.priority}
                                                       onChange={(event) => onPriorityChange(event, sortableItem)} />}
                                    label="Priorität"
                                    labelPlacement="top"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="primary" className={classes.deleteButton}
                                        onClick={(event) => onDeleteButtonClick(event, sortableItem)}>
                                    <RemoveCircleOutlineIcon/>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Draggable>
        );
    }

    let savedAlert = <></>
    switch (saved) {
        case 1:
            savedAlert =
                (<Snackbar open={true}>
                    <div>
                        <Alert severity="success">Erfolgreich abgespeichert!
                            Seite wird in 5 Sekunden neu
                            geladen <CircularProgress/>
                        </Alert>
                    </div>
                </Snackbar>)
            break;
        case 2:
            savedAlert =
                (<Snackbar open={true} autoHideDuration={5000}><Alert severity="info">Es wurde nichts verändert!</Alert></Snackbar>)
            break;
        case 3:
            savedAlert =
                (<Snackbar open={true} autoHideDuration={5000}>
                    <div>
                        <Alert severity="error">Es ist ein Fehler aufgetreten!
                            Seite wird in 5 Sekunden neu
                            geladen
                            <CircularProgress/>
                        </Alert>
                    </div>
                </Snackbar>)
            break;
        case 4:
            savedAlert =
                (<Snackbar open={true}>
                    <div>
                        <Alert severity="info">Überprüfe ob sich etwas geändert hat und bereite Daten für Transfer vor<CircularProgress/>
                        </Alert>
                    </div>
                </Snackbar>)
    }

    const keyMap = {
        SAVE: ["alt+shift+t"]
    };

    const handlers = {
        SAVE: () => {
            onSave()
        }
    };

    return <div>
        {savedAlert}
        <Fab className={classes.fab} color="primary" onClick={async () => await onSave()}><SaveIcon/></Fab>
        <HotKeys keyMap={keyMap} handlers={handlers}>
            <Grid container>
                <Grid item xs={6}/>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                            onClick={() => onLogout()}>Ausloggen</Button>
                </Grid>
            </Grid>
            <form>
                <FormGroup>
                    {sortableItems.map((group: ItemEntry[], groupIndex: number) => {
                        return (
                            <div>
                                {group.length > 0 ?
                                    <Typography variant={"h5"} component={"h5"}>{group[0].name}</Typography> : <></>}
                                <DragDropContext
                                    key={"drag-drop-context-" + groupIndex}
                                    onDragEnd={(event) => onDragEnd(event, group, groupIndex)}
                                >
                                    <Droppable
                                        droppableId="list">
                                        {provided => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {
                                                    group.map((item: ItemEntry, index: number) => (
                                                        <DraggableItem
                                                            item={item} itemIndex={index} groupIndex={groupIndex}
                                                            key={"draggable-item-" + groupIndex + "-" + index}/>
                                                    ))
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Button variant="contained" color="primary" className={classes.addButton}
                                        onClick={async (event) => await onAddButtonClick(event, group, groupIndex)}>
                                    <AddCircleOutlineIcon/>
                                </Button>
                                <Divider/>
                            </div>
                        )
                    })}
                </FormGroup>
            </form>
        </HotKeys>
    </div>
}
