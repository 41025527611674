import {ContentItems, getTypedItems} from "../utils/ContentsHelper";
import {Box, Link, Step, StepIconProps, StepLabel, Stepper} from "@material-ui/core";
import React from "react";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, #ffc18c 0%, #333 50%, #c3cfe2 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    disabled: {
        backgroundImage:
            'linear-gradient( 136deg, #434343 0%, #333 50%, #c3cfe2 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, #434343 0%, #333 50%, #c3cfe2 100%)',
    },
    stepLabel: {
        color: "#fff",
    }
});

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <PhoneForwardedIcon color={"secondary"}/>,
        2: <GroupAddIcon color={"secondary"}/>,
        3: <VideoLabelIcon color={"secondary"}/>,
    };
    let clsxClasses
    if (active || completed) {
        clsxClasses = clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
        })
    } else {
        clsxClasses = clsx(classes.root, classes.disabled, {
            [classes.active]: active,
            [classes.completed]: completed,
        })
    }
    return (
        <Link href={"tel:08337 7372"}>
            <div
                className={clsxClasses}
            >
                {icons[String(props.icon)]}
            </div>
        </Link>
    );
}

export default function HomeStepper(contents: ContentItems) {
    const classes = useColorlibStepIconStyles();

    const getStepper = (orientation: "horizontal" | "vertical") => {
        return (<Stepper orientation={orientation}>
            {getTypedItems(9, contents.items, classes.stepLabel).map((item, index) => {
                return (
                    <Step key={"flow-step-" + index} color={"secondary"}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}
                                   className={classes.stepLabel} color={"secondary"}>{item}</StepLabel>
                    </Step>
                )
            })}
        </Stepper>)
    }

    const horizontalStepper = getStepper("horizontal")
    const verticalStepper = getStepper("vertical")

    return (<>
            <Box
                display={{xs: 'none', sm: 'none', md: 'none', lg: 'block'}}>{horizontalStepper}</Box>
            <Box
                display={{xs: 'block', sm: 'block', md: 'block', lg: 'none'}}>
                {verticalStepper}
            </Box>
        </>
    )
}
