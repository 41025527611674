import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import {Button, CircularProgress, Container, FormGroup, Snackbar, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {BASE_URL} from "./Admin";
import Alert from "@material-ui/lab/Alert";

export type LoginType = {
    uuid: string,
    username: string,
    token: string
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const classes = useStyles();

    const [, setTokenCookie] = useCookies(['token']);
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [loginFailed, setLoginFailed] = React.useState(false)
    const [checkedLogin, setCheckedLogin] = React.useState(false);

    // Run only once
    useEffect(() => {
        checkLogin()
    }, [])

    function handleSubmit(event: any) {
        event.preventDefault();
        checkLogin();
    }

    function checkLogin(){
        axios.post<LoginType>(BASE_URL + "/accounts/login", {
            username: username,
            password: password
        }).then(result => {
            setCheckedLogin(true)
            if (result.status === 200) {
                setLoginFailed(false)
                setTokenCookie('token', result.data.token)
                window.location.reload()
            } else {
                setLoginFailed(true)
            }
        }).catch(reason => {
            setLoginFailed(true)
            setCheckedLogin(true)
        })
    }

    if(!checkedLogin){
        return (
            <Snackbar open={true} autoHideDuration={5000}>
                <div>
                    <Alert severity="success">Es wird geschaut ob du eingeloggt bist und die Konfiguration geladen<CircularProgress/>
                    </Alert>
                </div>
            </Snackbar>
        )
    }

    const onChangeUsername = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUsername(event.target.value.trim())
    }
    const onChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassword(event.target.value.trim())
    }

    return (<Container>
        <div className={classes.paper}>
            <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
                <FormGroup>
                    <TextField margin="normal" label={"Benutzername"} variant="outlined" required
                               id={"username"} autoComplete={"username"} autoFocus value={username}
                               error={loginFailed}
                               onChange={(event) => onChangeUsername(event)}/>
                    <TextField margin="normal" label={"Passwort"} variant="outlined" required type="password"
                               id={"password"} autoComplete={"password"} value={password}
                               error={loginFailed}
                               onChange={(event) => onChangePassword(event)}/>
                    {loginFailed ? <Alert severity="error">Benutzername oder Passwort falsch</Alert> : <></>}
                    <Button variant={"contained"}
                            type="submit"
                            className={classes.submit} color={"primary"}>Einloggen</Button>
                </FormGroup>
            </form>
        </div>
    </Container>)
}
