import React from "react";
import {
    Container,
    createStyles,
    Grid,
    Link,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import footer from "../images/Footer/footer.jpg"
import {Parallax} from 'react-parallax';
// @ts-ignore
import Plx from 'react-plx';
import {Routes} from "../App";
import logo from "../images/Header/Logo.webp";
import contentContentBackgroundImage from "../images/Home/background.webp";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            textAlign: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            position: "relative",
            overflow: "hidden",
        },
        parralax: {
            height: "50vh"
        },
        links: {
            verticalAlign: "middle",
            textAlign: "center",
            marginTop: theme.spacing(2)
        },
        logo: {
            objectFit: "contain",
            height: "7vh",
        },
    }),
);

export default function Footer(routes: Routes) {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Parallax bgImage={contentContentBackgroundImage} strength={500}>
                {/*<Parallax className={classes.parralax} bgImage={footer} strength={500}/>*/}
                <Container>
                    <Grid container spacing={3}>
                        <Grid item sm={3} className={classes.links}>
                            <Typography component={"p"}>
                                © 2021 Schreinerei Wiest
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <img src={logo} alt="Wiest" className={classes.logo}/>
                        </Grid>
                        <Grid item container sm={3} spacing={3}>
                            <Grid item md={6} className={classes.links}>
                                <Typography component={"p"}><Link
                                    color={"secondary"}
                                    href={routes.impressumRoute}>Impressum</Link></Typography>
                            </Grid>
                            <Grid item md={6} className={classes.links}>
                                <Typography component={"p"}><Link
                                    color={"secondary"}
                                    href={routes.datenschutzRoute}>Datenschutz</Link></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Parallax>
        </footer>
    )
}
