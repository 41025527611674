import React from 'react';
import {ItemEntry} from "../views/admin/Admin"
import {Avatar, Link, Typography} from "@material-ui/core";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

export type ContentItems = {
    items: ItemEntry[][]
}

export function getItemsWithGroupIndex(groupIndex: number, entries: ItemEntry[]) {
    let foundItems: ItemEntry[] = [];
    for (const entry of entries) {
        if (entry.group_id === groupIndex) {
            foundItems.push(entry);
        }
    }
    return foundItems;
}

export function getGroupedGroups(items: ItemEntry[]) {
    let groups: ItemEntry[][] = []
    let group: ItemEntry[] = []
    items.forEach((item: ItemEntry, index: number) => {
        if (group.length > 0 && group[0].group_id !== item.group_id) {
            groups.push(group)
            group = []
            group.push(item)

        } else {
            group.push(item)
        }

        if (index + 1 >= items.length) {
            groups.push(group)
        }
    })
    return groups
}

export function getTypedItems(groupIndex: number, items: ItemEntry[][], className?: string, route?: string) {
    if(!items || items.length === 0 || groupIndex === -1) return [];
    const group: ItemEntry[] = items[groupIndex - 1];
    const returnItems: ReactJSXElement[] = []
    group.map((item, index) => {
        const lineBreakedContent = getLineBreakedContent(item.content)
        switch (item.type) {
            // Text
            case 1:
                returnItems.push(<Typography className={className} component={"p"}>{lineBreakedContent}</Typography>)
                break;
            // Title
            case 2:
                returnItems.push(<Typography className={className} component={"h1"}
                                             variant={"h1"}>{lineBreakedContent}</Typography>)
                break;
            // Subtitle
            case 3:
                returnItems.push(<Typography className={className} component={"h2"}
                                             variant={"h2"}>{lineBreakedContent}</Typography>)
                break;
            // 3. Title
            case 4:
                returnItems.push(<Typography className={className} component={"h3"}
                                             variant={"h3"}>{lineBreakedContent}</Typography>)
                break;
            // 4. Title
            case 5:
                returnItems.push(<Typography className={className} component={"h4"}
                                             variant={"h4"}>{lineBreakedContent}</Typography>)
                break;
            // 5. Title
            case 6:
                returnItems.push(<Typography className={className} component={"h5"}
                                             variant={"h5"}>{lineBreakedContent}</Typography>)
                break;
            // 6. Title
            case 7:
                returnItems.push(<Typography className={className} component={"h6"}
                                             variant={"h6"}>{lineBreakedContent}</Typography>)
                break;
            // Link
            case 8:
                const linkContent = getLinkContent(item.content)
                returnItems.push(<Typography className={className}>
                    <Link className={className} href={item.content}
                          color={"primary"}>{linkContent}</Link></Typography>)
                break;
            // Email Link
            case 9:
                returnItems.push(<Typography component={"h6"}
                                             variant={"h6"} className={className}>
                    <Link className={className}
                          href={"mailto:" + item.content}
                          color={"primary"}>{lineBreakedContent}</Link></Typography>)
                break;
            // Telefon Link
            case 10:
                console.log(item.content.replaceAll("[A-Za-z\:\s]", ""))
                returnItems.push(<Typography component={"h6"}
                                             variant={"h6"} className={className}>
                    <Link className={className} href={"tel:" + item.content.replaceAll(/[A-Za-z:\s]/g, "")}
                          color={"primary"}>{lineBreakedContent}</Link></Typography>)
                break;
            // Image
            case 11:
                returnItems.push(<div><img className={className} src={item.content} alt={"Image"}/></div>)
                break;
            case 12:
                // Avatar
                returnItems.push(<div><Avatar className={className} src={item.content} alt={"Image"}/></div>)
                break;
            case 13:
                // Liste
                const listBreakedContent = getListBreakedContent(item.content)
                returnItems.push(<Typography className={className} component={"p"}>{listBreakedContent}</Typography>)
                break;
        }
    })
    return returnItems;
}

export function getPlainItems(groupIndex: number, items: ItemEntry[][], className?: string, route?: string) {
    console.log(items);
    console.log(groupIndex);
    if(!items || items.length === 0 || groupIndex === -1) return [];
    const group: ItemEntry[] = items[groupIndex - 1];
    const returnItems: string[] = []
    group.map((item, index) => {
        returnItems.push(item.content)
    })
    return returnItems;
}

function getListBreakedContent(content: string) {
    let lines: string[] = content.split("\n")
    return (
        <>
            <ul>
                {lines.map(line => {
                    if (lines.length === 1) return (<>{line}</>)
                    return (<li>{line}</li>)
                })}
            </ul>
        </>
    )
}

function getLinkContent(content: string) {
    let lines: string[] = content.split("\n")
    return (
        <>
            <ul>
                {lines.map(line => {
                    line = line.replace("https://", "").replace("http://", "")
                    if (lines.length === 1) return (<>{line}</>)
                    return (<li>{line}</li>)
                })}
            </ul>
        </>
    )
}

function getLineBreakedContent(content: string) {
    let lines: string[] = content.split("\n")
    return (
        <>
            {lines.map(line => {
                if (lines.length === 1) return (<>{line}</>)
                return (<>{line}<br/></>)
            })}
        </>
    )
}

export function getGroupedItems(groupIndex: number, items: ItemEntry[][]) {
    if(!items || items.length === 0 || groupIndex === -1) return [];
    const group: ItemEntry[] = items[groupIndex - 1];
    return group;
}
