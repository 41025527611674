import {AppBar, Tab, Tabs, Toolbar, Typography, Box} from "@material-ui/core";
import React, {useEffect} from "react";
import ContentConfig from "./ContentConfig";
import {Content, ContentTypes, BASE_URL} from "./Admin";
import {getLoadingScreen, useStyles as AppUseStyles} from "../../App";
import axios from "axios";
import {useCookies} from "react-cookie";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const typeUrls = ["home", "gallery", "contact", "press", "aboutus"]

export default function AdminHeader() {
    const [content, setContent] = React.useState<Content>();
    const [types, setTypes] = React.useState<ContentTypes>();

    const [selectedAdminTab, setSelectedAdminTab] = useCookies(['adminTab']);
    const [value, setValue] = React.useState(selectedAdminTab.adminTab === undefined ? 0 : parseInt(selectedAdminTab.adminTab));

    const appClasses = AppUseStyles()
    const loadingScreen = getLoadingScreen(appClasses)

    // Run only once
    useEffect(() => {
        updateTexts(value);
        updateTypes();
    }, [])

    const updateTexts = (newValue: number) => {
        let requestUrl = BASE_URL;
        console.log(newValue)
        switch (newValue) {
            default:
                requestUrl += "/texts/home" + typeUrls[0] + "/admin"
                break;
            case 0:
                requestUrl += "/texts/" + typeUrls[0] + "/admin"
                break;
            case 1:
                requestUrl += "/texts/" + typeUrls[1] + "/admin"
                break;
            case 2:
                requestUrl += "/texts/" + typeUrls[2] + "/admin"
                break;
            case 3:
                requestUrl += "/texts/" + typeUrls[3] + "/admin"
                break;
            case 4:
                requestUrl += "/texts/" + typeUrls[4] + "/admin"
                break;
        }
        axios.get<Content>(requestUrl).then(result => {
            const data = result.data;
            setContent(data);
        })
    }

    const updateTypes = () => {
        axios.get<ContentTypes>(BASE_URL + "/types").then(result => {
            const data = result.data;
            setTypes(data);
        })
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setSelectedAdminTab('adminTab', newValue)
        updateTexts(newValue);
    };

    if (content === undefined || types === undefined || content.content.length <= 0) {
        return (<div>{loadingScreen}</div>)
    }

    const tabPanels = []
    console.log(content.content)
    for (let i = 0; i < 5; i++) {
        let contentconfig = <div/>
        if (i === value) contentconfig =
            <ContentConfig content={content.content} baseUrl={BASE_URL} types={types.types} typeUrl={typeUrls[i]}/>
        console.log(contentconfig)
        tabPanels.push(
            <TabPanel value={value} index={i}>
                {contentconfig}
            </TabPanel>
        )
    }

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <Tabs
                        onChange={handleChange}
                        value={value}
                    >
                        <Tab label={"Home"}/>
                        <Tab label={"Galerie"}/>
                        <Tab label={"Kontakt"}/>
                        <Tab label={"Presse"}/>
                        <Tab label={"Über uns"}/>
                    </Tabs>
                </Toolbar>
            </AppBar>
            {tabPanels}
        </div>
    )
}
