import React, {useEffect, useState} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from './views/Home'
import Gallery from "./views/Gallery";
import Header from "./templates/Header";
import {createStyles, makeStyles, Theme, ThemeProvider} from '@material-ui/core';
import WoodTheme from "./themes/WoodTheme";
import Footer from "./templates/Footer";
import {ParallaxProvider} from 'react-scroll-parallax';
import Admin, {BASE_URL, Content, ItemEntry} from "./views/admin/Admin";
import ReactLoading from 'react-loading';
import "react-image-gallery/styles/css/image-gallery.css";
import Contact from "./views/Contact";
import AboutUs from "./views/AboutUs";
import Press from "./views/Press";
import Impressum from "./views/right/Impressum";
import Datenschutz from "./views/right/Datenschutz";
import axios from "axios";
import {getGroupedGroups} from "./utils/ContentsHelper";

export const currentTheme: Theme = WoodTheme;

export type Routes = {
    homeRoute: string,
    galleryRoute: string,
    contactRoute: string,
    aboutUsRoute: string,
    pressRoute: string,
    impressumRoute: string,
    datenschutzRoute: string,
    currentRoute: string
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            backgroundColor: currentTheme.palette.primary.main,
            flex: 1,
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000
        },
        spinner: {
            paddingTop: "calc(50vh - 50px)",
            paddingLeft: "calc(50vw - 50px)",
        },
        website: {
            zIndex: 50
        }
    }),
);


export default function App() {
    const classes = useStyles();

    const routes: Routes = {
        homeRoute: "/",
        galleryRoute: "/galerie",
        contactRoute: "/kontakt",
        aboutUsRoute: "/über-uns",
        pressRoute: "/presse",
        impressumRoute: "/impressum",
        datenschutzRoute: "/datenschutz",
        currentRoute: "/"
    }

    const [homeContents, setHomeContents] = useState<ItemEntry[][]>([]);
    const [homeContentsNoPrio, setHomeContentsNoPrio] = useState<ItemEntry[][]>([]);
    const [galleryContents, setGalleryContents] = useState<ItemEntry[][]>([]);
    const [galleryContentsNoPrio, setGalleryContentsNoPrio] = useState<ItemEntry[][]>([]);
    const [contactContents, setContactContents] = useState<ItemEntry[][]>([]);
    const [contactContentsNoPrio, setContactContentsNoPrio] = useState<ItemEntry[][]>([]);
    const [pressContents, setPressContents] = useState<ItemEntry[][]>([]);
    const [pressContentsNoPrio, setPressContentsNoPrio] = useState<ItemEntry[][]>([]);
    const [aboutUsContents, setAboutUsContents] = useState<ItemEntry[][]>([]);
    const [aboutUsContentsNoPrio, setAboutUsContentsNoPrio] = useState<ItemEntry[][]>([]);

    const updateHomeTexts = () => {
        var t0 = performance.now()
        axios.get<Content>(BASE_URL + "/texts/home").then(result => {
            const data = result.data;
            setHomeContents(getGroupedGroups(data.content))
            var t1 = performance.now()
            console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.")
        })
        axios.get<Content>(BASE_URL + "/texts/home/noprio").then(result => {
            const data = result.data;
            setHomeContentsNoPrio(getGroupedGroups(data.content))
            var t1 = performance.now()
            console.log("Noprio Call to doSomething took " + (t1 - t0) + " milliseconds.")
        })
    }

    const updateGalleryTexts = () => {
        axios.get<Content>(BASE_URL + "/texts/gallery").then(result => {
            const data = result.data;
            setGalleryContents(getGroupedGroups(data.content))
        })
        axios.get<Content>(BASE_URL + "/texts/gallery/noprio").then(result => {
            const data = result.data;
            setGalleryContentsNoPrio(getGroupedGroups(data.content))
        })
    }

    const updateContactTexts = () => {
        axios.get<Content>(BASE_URL + "/texts/contact").then(result => {
            const data = result.data;
            setContactContents(getGroupedGroups(data.content))
        })
        axios.get<Content>(BASE_URL + "/texts/contact/noprio").then(result => {
            const data = result.data;
            setContactContentsNoPrio(getGroupedGroups(data.content))
        })
    }

    const updatePressTexts = () => {
        axios.get<Content>(BASE_URL + "/texts/press").then(result => {
            const data = result.data;
            setPressContents(getGroupedGroups(data.content))
        })
        axios.get<Content>(BASE_URL + "/texts/press/noprio").then(result => {
            const data = result.data;
            setPressContentsNoPrio(getGroupedGroups(data.content))
        })
    }

    const updateAboutUsTexts = () => {
        axios.get<Content>(BASE_URL + "/texts/aboutus").then(result => {
            const data = result.data;
            setAboutUsContents(getGroupedGroups(data.content))
        })
        axios.get<Content>(BASE_URL + "/texts/aboutus/noprio").then(result => {
            const data = result.data;
            setAboutUsContentsNoPrio(getGroupedGroups(data.content))
        })
    }

    useEffect(() => {
        updateHomeTexts()
        updateGalleryTexts()
        updateContactTexts()
        updatePressTexts()
        updateAboutUsTexts()
    }, [])


    const website = (
            <div className={classes.website}>
                <Route path={routes.homeRoute} render={(history) => (
                    <Header
                        homeRoute={routes.homeRoute}
                        galleryRoute={routes.galleryRoute}
                        aboutUsRoute={routes.aboutUsRoute}
                        contactRoute={routes.contactRoute}
                        pressRoute={routes.pressRoute}
                        impressumRoute={routes.impressumRoute}
                        datenschutzRoute={routes.datenschutzRoute}
                        currentRoute={history.location.pathname}/>
                )}>
                </Route>
                <Switch>
                    <Route path={routes.galleryRoute}>
                        <Gallery contents={galleryContents} contentsNoPrio={galleryContentsNoPrio}/>
                    </Route>
                    <Route path={routes.aboutUsRoute}>
                        <AboutUs contents={aboutUsContents} contentsNoPrio={aboutUsContentsNoPrio}/>
                    </Route>
                    <Route path={routes.contactRoute}>
                        <Contact contents={contactContents} contentsNoPrio={contactContentsNoPrio}/>
                    </Route>
                    <Route path={routes.pressRoute}>
                        <Press contents={pressContents} contentsNoPrio={pressContentsNoPrio}/>
                    </Route>
                    <Route path={routes.impressumRoute}>
                        <Impressum/>
                    </Route>
                    <Route path={routes.datenschutzRoute}>
                        <Datenschutz/>
                    </Route>
                    {/*Needs to be at bottom*/}
                    <Route path={routes.homeRoute}>
                        <Home contents={homeContents} contentsNoPrio={homeContentsNoPrio}/>
                    </Route>
                </Switch>
                <Footer homeRoute={routes.homeRoute}
                        galleryRoute={routes.galleryRoute}
                        aboutUsRoute={routes.aboutUsRoute}
                        contactRoute={routes.contactRoute}
                        pressRoute={routes.pressRoute}
                        impressumRoute={routes.impressumRoute}
                        datenschutzRoute={routes.datenschutzRoute}
                        currentRoute={routes.currentRoute}/>
            </div>
    )

    return (
        <ThemeProvider theme={WoodTheme}>
            <ParallaxProvider>
                <Router>
                    {window.location.pathname === "/admin" ? <Admin/> : [website]}
                </Router>
            </ParallaxProvider>
        </ThemeProvider>
    );
}

export function getLoadingScreen(classes: any) {
    return (<div className={classes.loading}>
        <ReactLoading className={classes.spinner}
                      type={"spinningBubbles"} height={100} width={100}/>
    </div>)
}
