import {Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import {ContentItems, getTypedItems} from "../utils/ContentsHelper";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
// @ts-ignore
import Plx from "react-plx";
import contentContentBackgroundImage from "../images/Home/background.webp";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardsDiv: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        card: {
            margin: theme.spacing(1),
            height: "100%",
            backgroundImage: `url(${contentContentBackgroundImage})`,
            background: theme.palette.primary.main,
            color: theme.palette.secondary.main
        },
        cardContent: {
            paddingBottom: theme.spacing(1),
            width: "100%",
            overflow: "hidden",
            objectFit: "cover"
        },
    }),
);

const plxData = {
    data: [
        {
            start: 'self',
            duration: 300,
            name: 'first',
            properties: [
                {
                    startValue: 0,
                    endValue: 1,
                    property: 'opacity',
                },
            ],
        },
        {
            start: 'self',
            duration: "40vh",
            startOffset: '-10vh',
            name: 'first',
            properties: [

                {
                    startValue: 20,
                    endValue: 0,
                    property: 'translateY',
                    unit: 'vh',
                },
            ],
        },
    ],
}

export default function Cards(contents: ContentItems) {
    const classes = useStyles();
    const cards = []
    for (let i = 0; i < 4; i++) {
        const groupIndex = i + 4;
        cards.push(
            <Grid item md={3}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            {getTypedItems(groupIndex, contents.items, classes.cardContent).map((item) => {
                                return (item)
                            })}
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        )
    }
    return (
        <Plx
            key={"cards-items"}
            parallaxData={plxData.data}
        >
            <Grid container>
                {cards}
            </Grid>
        </Plx>
    );
}
