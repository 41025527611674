import {createMuiTheme} from '@material-ui/core/styles';
import {responsiveFontSizes} from "@material-ui/core";

let WoodTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#333",
            // main: "#aa3517"
        },
        secondary: {
            main: "#fff",
            // main: "#aa3517",
        },

        background: {
            default: "#fff",
            paper: "fff",
        }
    },
    typography: {
        fontSize: 16,
        h1: {
            // wordWrap: "break-word"
        }
    }
});
WoodTheme = responsiveFontSizes(WoodTheme)

export default WoodTheme;
