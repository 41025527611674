import ImageGallery from 'react-image-gallery';
import {
    Box,
    Container,
    Tab,
    Tabs,
    Divider,
    Grid,
    SwipeableDrawer,
    Fab,
    Toolbar,
    Card,
    CardActionArea,
    CardMedia,
    CardContent, Button
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {BASE_URL, Content, ItemEntry} from "./admin/Admin";
import axios from "axios";
import {
    getGroupedGroups,
    getGroupedItems,
    getPlainItems,
    getTypedItems
} from "../utils/ContentsHelper";
import {getLoadingScreen, useStyles as AppUseStyles} from "../App";
import MenuIcon from "@material-ui/icons/Menu";
import contentContentBackgroundImage from "../images/Home/background.webp";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Helmet} from "react-helmet";

export type GalleryContents = {
    contents: ItemEntry[][],
    contentsNoPrio: ItemEntry[][],
}


interface TabPanelProps {
    children?: React.ReactNode;
    contents: ItemEntry[][];
    contentsNoPrio: ItemEntry[][];
    group: number;
    groupNoPrio: number;
    imageGroup: number;
    imageDescriptionGroup: number;
    imageGroupNoPrio: number,
    imageDescriptionGroupNoPrio: number;
    index: any;
    value: any;
}

interface ReferencesTabPanelProps {
    children?: React.ReactNode;
    contents: ItemEntry[][];
    group: number;
    imageGroup: number;
    linkGroup: number;
    index: any;
    value: any;
}


const useStyles = makeStyles((theme: Theme) => ({
    galleryContent: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        background: theme.palette.primary.main,
        height: "91vh",
        position: "fixed",
    },
    drawerTabs: {
        borderRight: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        background: theme.palette.primary.main,
        height: "100%",
    },
    gallery: {
        position: "relative",
        // color: theme.palette.secondary.main,
        // background: theme.palette.primary.main,
        // backgroundImage: `url(${contentContentBackgroundImage})`,
    },
    originalClass: {
        objectFit: "contain",
    },
    galleryItems: {
        textAlign: "center",
        marginBottom: theme.spacing(5)
    },
    tabPanels: {
        width: "100%"
    },
    ausstellungContent: {
        flexGrow: 1,
        // backgroundColor: theme.palette.primary.main,
        display: 'flex',
        height: "100%",
        color: theme.palette.primary.main,
    },
    ausstellungText: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: "center"
    },
    ausstellungGallery: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        position: "relative",
        width: "100%"
    },
    drawer: {
        color: theme.palette.secondary.main,
        background: theme.palette.primary.main,
    },
    drawerButton: {
        background: theme.palette.primary.main,
        position: "fixed",
        zIndex: 200,
        margin: theme.spacing(1),
    },
    drawerButtonInsideDrawer: {
        background: theme.palette.primary.main,
        position: "relative",
        zIndex: 200,
        margin: theme.spacing(1),
    },
    fixedTabs: {
        position: "fixed"
    },
    references: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: theme.spacing(3),
    },
    referencesImages: {
        width: "100%",
        overflow: "hidden",
        objectFit: "cover"
    },
    referenceTexts: {
        textAlign: "center",
        paddingTop: theme.spacing(2),
        color: theme.palette.secondary.main,
    },
    referencesContainer: {
        marginTop: theme.spacing(3),
        overflow: "hidden",
        paddingBottom: theme.spacing(5)
    },
    card: {
        height: "100%",
        backgroundImage: `url(${contentContentBackgroundImage})`,
        background: theme.palette.primary.main,
    },
    cardImage: {
        margin: theme.spacing(2)
    }
}));

function ReferencesTabPanel(props: ReferencesTabPanelProps) {
    const classes = useStyles();
    const {children, value, index, contents, group, imageGroup, linkGroup, ...other} = props;

    const referencesImages: JSX.Element[] = getTypedItems(imageGroup, contents, classes.referencesImages).map((item, index) => {
        return (item)
    })

    const referencesTexts: JSX.Element[] = getTypedItems(group, contents, classes.referenceTexts).map((item, index) => {
        return (item)
    })

    const referencesLinks: JSX.Element[] = getTypedItems(linkGroup, contents, classes.referenceTexts).map((item, index) => {
        return (item)
    })


    let referencesCount = referencesImages.length;
    if (referencesTexts.length > referencesImages.length) referencesCount = referencesTexts.length;
    if (referencesLinks.length > referencesTexts.length) referencesCount = referencesLinks.length;


    let referencesItems = []

    for (let i = 0; i < referencesCount; i++) {
        referencesItems.push(
            <>
                <Grid item md={3} alignItems={"center"}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia className={classes.cardImage}>
                                {referencesImages[i]}
                            </CardMedia>
                            <CardContent>
                                {referencesTexts[i]}
                                {referencesLinks[i]}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </>
        )
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            className={classes.gallery}
        >
            {value === index && (
                <>
                    <Container>
                        <Grid container className={classes.referencesContainer} spacing={3}
                              justify="space-evenly">
                            {referencesItems}
                        </Grid>
                    </Container>
                </>
            )}
        </div>)
}

function TabPanel(props: TabPanelProps) {
    const classes = useStyles();
    const {
        children,
        value,
        index,
        contents,
        contentsNoPrio,
        group,
        groupNoPrio,
        imageGroup,
        imageGroupNoPrio,
        imageDescriptionGroup,
        imageDescriptionGroupNoPrio,
        ...other
    } = props;
    const images: any[] = [];
    let descriptions = getPlainItems(imageDescriptionGroup, contents)
    let descriptionsNoPrio = getPlainItems(imageDescriptionGroupNoPrio, contentsNoPrio)
    const isSmall = useMediaQuery(useTheme().breakpoints.down('sm'));

    getGroupedItems(imageGroup, contents).map((item, index) => {
        images.push({
            original: item.content, thumbnail: item.content, originalClass: classes.originalClass,
            description: descriptions.length > index ? descriptions[index] : ""
        });
    })
    getGroupedItems(imageGroupNoPrio, contentsNoPrio).map((item, index) => {
        images.push({
            original: item.content, thumbnail: item.content, originalClass: classes.originalClass,
            description: descriptionsNoPrio.length > index ? descriptionsNoPrio[index] : "",
            height: "50px"
        });
    })
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            className={classes.gallery}
        >
            {value === index && (
                <Grid item alignContent={"center"}>
                    <Box p={3} className={classes.galleryItems}>
                        {getTypedItems(group, contents).map((item, itemIndex) => {
                            return (item)
                        })}
                        {getTypedItems(groupNoPrio, contentsNoPrio).map((item, itemIndex) => {
                            return (item)
                        })}
                        <Box p={3} border={0}>
                            <ImageGallery
                                additionalClass={classes.gallery}
                                items={images}
                                thumbnailPosition={"right"}
                                showBullets
                                lazyLoad={true}
                            />
                        </Box>
                    </Box>
                </Grid>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export default function Gallery(galleryContents: GalleryContents) {
    const classes = useStyles();
    const {contents, contentsNoPrio} = galleryContents;

    const [value, setValue] = React.useState(0);
    const [openDrawer, setOpenDrawer] = useState(false);

    const appClasses = AppUseStyles()
    const loadingScreen = getLoadingScreen(appClasses)

    if (contents.length <= 0) {
        return (<div>
            {loadingScreen}
        </div>)
    }

    const toggleDrawer = (open: boolean) => {
        setOpenDrawer(open);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    // const ausstellungImages: any[] = [];
    // getGroupedItems(25, contents).map((item, index) => {
    //     ausstellungImages.push({original: item.content, thumbnail: item.content, originalClass: classes.originalClass})
    // })

    const getTabs = (classname: string) => (<Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classname}
        scrollButtons="auto"
    >
        {getTypedItems(7, contents).map((item, index) => {
            return (<Tab label={item} onClick={() => toggleDrawer(false)} wrapped {...a11yProps(index)}/>)
        })}
    </Tabs>)

    const tabs = getTabs(classes.tabs)
    const drawerTabs = getTabs(classes.drawerTabs)

    const drawer =
        (<>
            <Box
                display={{xs: 'none', sm: 'none', md: 'none', lg: 'block'}}>{tabs}</Box>
            <Box
                display={{xs: 'block', sm: 'block', md: 'block', lg: 'none'}}>
                <Fab onClick={() => toggleDrawer(true)} className={classes.drawerButton}><MenuIcon color={"secondary"}/></Fab>
            </Box>
            <SwipeableDrawer classes={{paper: classes.drawer}} variant="temporary" open={openDrawer}
                             anchor="left" onClose={() => toggleDrawer(true)}
                             onOpen={() => toggleDrawer(true)}>
                <Toolbar>
                    <Fab onClick={() => toggleDrawer(false)} className={classes.drawerButtonInsideDrawer}><MenuIcon
                        color={"secondary"}/></Fab>
                </Toolbar>
                {drawerTabs}</SwipeableDrawer>
        </>)


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Galerie - Schreinerei Wiest</title>
                <meta
                    name="description"
                    content="Lassen Sie sich durch unsere Galerie inspirieren und finden sie den perfekten Look"
                />
            </Helmet>
            <div className={classes.galleryContent}>

                <Grid container>
                    <Grid item md={2}>
                        {drawer}
                    </Grid>

                    <Grid item md={10}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.tabPanels}>
                                    <TabPanel value={value} index={0} group={1} imageGroup={8}
                                              imageDescriptionGroup={27} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={-1} imageGroupNoPrio={-1}/>
                                    <TabPanel value={value} index={1} group={2} imageGroup={9}
                                              imageDescriptionGroup={28} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={11} imageGroupNoPrio={1}/>
                                    <TabPanel value={value} index={2} group={3} imageGroup={10}
                                              imageDescriptionGroup={29} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={12} imageGroupNoPrio={2}/>
                                    <TabPanel value={value} index={3} group={4} imageGroup={11}
                                              imageDescriptionGroup={30} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={13} imageGroupNoPrio={3}/>
                                    <TabPanel value={value} index={4} group={14} imageGroup={15}
                                              imageDescriptionGroup={34} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={16} imageGroupNoPrio={6}/>
                                    <TabPanel value={value} index={5} group={5} imageGroup={12}
                                              imageDescriptionGroup={31} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={14} imageGroupNoPrio={4}/>
                                    <TabPanel value={value} index={6} group={6} imageGroup={13}
                                              imageDescriptionGroup={32} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={15} imageGroupNoPrio={5}/>
                                        {/*Gewerbe*/}
                                    <TabPanel value={value} index={7} group={16} imageGroup={17}
                                              imageDescriptionGroup={35} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={17} imageGroupNoPrio={7}/>
                                    <TabPanel value={value} index={8} group={18} imageGroup={19}
                                              imageDescriptionGroup={36} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={18} imageGroupNoPrio={8}/>
                                    <TabPanel value={value} index={9} group={20} imageGroup={21}
                                              imageDescriptionGroup={37} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={19} imageGroupNoPrio={9}/>
                                    <TabPanel value={value} index={10} group={22} imageGroup={23}
                                              imageDescriptionGroup={38} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={20} imageGroupNoPrio={10}/>
                                    <TabPanel value={value} index={11} group={41} imageGroup={39}
                                              imageDescriptionGroup={40} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={22} imageGroupNoPrio={21}/>
                                    <TabPanel value={value} index={12} group={42} imageGroup={43}
                                              imageDescriptionGroup={44} contents={contents}
                                              contentsNoPrio={contentsNoPrio} groupNoPrio={-1}
                                              imageDescriptionGroupNoPrio={24} imageGroupNoPrio={23}/>
                                    <ReferencesTabPanel contents={contents} group={25} imageGroup={24} linkGroup={26}
                                                        index={13}
                                                        value={value}/>
                                </div>
                            </Grid>
                            {/*<Grid item xs={11}>*/}
                            {/*    <Divider/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <Container className={classes.ausstellungText}>*/}
                            {/*        {getTypedItems(24, contents).map((item, itemIndex) => {*/}
                            {/*            return (item)*/}
                            {/*        })}*/}
                            {/*        <Box p={3}>*/}
                            {/*            <ImageGallery*/}
                            {/*                additionalClass={classes.ausstellungGallery}*/}
                            {/*                items={ausstellungImages}*/}
                            {/*                thumbnailPosition={"right"}*/}
                            {/*                lazyLoad={true}/>*/}
                            {/*        </Box>*/}
                            {/*    </Container>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
