import React, {useEffect, useState} from "react";
import {BASE_URL, Content, ItemEntry} from "./admin/Admin";
import axios from "axios";
import {getGroupedGroups, getTypedItems} from "../utils/ContentsHelper";
import {getLoadingScreen, useStyles as AppUseStyles} from "../App";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {
    Divider,
    Grid,
    Container,
    Card,
    Typography,
    CardActionArea,
    CardContent
} from "@material-ui/core";
import Carousel from "react-multi-carousel";
// @ts-ignore
import Plx from 'react-plx';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import HistoryIcon from '@material-ui/icons/History';
import GroupIcon from '@material-ui/icons/Group';
import {CountUp} from 'use-count-up'
import CreateIcon from '@material-ui/icons/Create'
import contentContentBackgroundImage from "../images/Home/background.webp";
import {Helmet} from "react-helmet";

export type AboutUsContents = {
    contents: ItemEntry[][],
    contentsNoPrio: ItemEntry[][],
}

const imageHeight: string = "50vh"

const useStyles = makeStyles((theme: Theme) => ({
    historyTexts: {
        textAlign: "center",
        margin: theme.spacing(3)
    },
    centerImages: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    centerText: {
        textAlign: "center"
    },
    employeeImage: {
        margin: 0,
        height: imageHeight,
        "&:hover": {
            filter: `grayscale(0.5)`
        },
    },
    company: {
        marginTop: "100vh",
        // backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    history: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.main
    },
    companyTitle: {
        textAlign: "center"
    },
    companyImage: {
        objectFit: "cover",
        width: "100%",
        height: "100vh",
    },
    carouselDiv: {
        // overflowX: "visible",
        padding: 0,
        top: 0,
        width: "100%",
        position: "absolute"
    },
    plx: {
        position: "absolute",
        top: "40vh",
        textAlign: "center",
        overflow: "hidden",
        width: "100%"
    },
    headerTitle: {
        // fontWeight: "bold",
        // color: "#aa3517",
        color: theme.palette.secondary.main,
        fontFamily: "HeaderFont",
        // color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        border: "4px solid #aa3517",
        backgroundColor: "rgba(51, 51, 51, 0.5)",
        wordWrap: "break-word",
        [theme.breakpoints.up("xs")]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
        },
        [theme.breakpoints.up("sm")]: {
            display: "inline",
        },
        opacity: 1,
    },
    card: {
        height: "100%",
        backgroundImage: `url(${contentContentBackgroundImage})`,
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
    },
    cardText: {
        textAlign: "center",
    },
    icons: {
        fontSize: "5em",
        width: "10vw"
    },
    machineryText: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        textAlign: "center"
    },
    machineryImage: {
        width: "100%",
    }
}));

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    }
};

const textData = [
    {
        start: 0,
        startOffset: '0vh',
        duration: '25vh',
        properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            }
        ],
    },
    {
        start: 0,
        startOffset: '0vh',
        duration: '100vh',
        properties: [
            {
                startValue: 0,
                endValue: -50,
                unit: 'vh',
                property: 'translateY',
            },
        ],
    },
];


const plxData = {
    data: [
        {
            start: 'self',
            duration: 300,
            name: 'first',
            properties: [
                {
                    startValue: 0,
                    endValue: 1,
                    property: 'opacity',
                },
            ],
        },
        {
            start: 'self',
            duration: "40vh",
            startOffset: '-10vh',
            name: 'first',
            properties: [

                {
                    startValue: 20,
                    endValue: 0,
                    property: 'translateY',
                    unit: 'vh',
                },
            ],
        },
    ],
}

export default function AboutUs(aboutUsContents: AboutUsContents) {
    const classes = useStyles();
    const {contents, contentsNoPrio} = aboutUsContents;

    const [isCounting, setIsCounting] = useState(false);

    const appClasses = AppUseStyles()
    const loadingScreen = getLoadingScreen(appClasses)

    if (contents.length <= 0) {
        return (<div>
            {loadingScreen}
        </div>)
    }

    const historyImages: JSX.Element[] = getTypedItems(4, contents, classes.centerImages).map((item, index) => {
        return (item)
    })

    const historyTexts: JSX.Element[] = getTypedItems(5, contents, classes.historyTexts).map((item, index) => {
        return (item)
    })

    const company = (
        <>
            {/*{getTypedItems(1, contents, classes.companyTitle).map((item, index) => {*/}
            {/*    return (item)*/}
            {/*})}*/}
            <Grid container spacing={3}>
                {getTypedItems(3, contents).map((item, index) => {
                    return (
                        <Grid item md={3}>
                            <Card className={classes.card}>
                                <CardActionArea>
                                    <CardContent>
                                        {item}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>)
                })}
            </Grid>
        </>
    )

    const history = (
        <div>
            <Container>
                {historyTexts[0]}
                {historyTexts[1]}
                {historyImages[0]}
                <Divider/>
                <Grid container>
                    <Grid item xs={4}>
                        {historyImages[1]}
                    </Grid>
                    <Grid item xs={4}>
                        {historyTexts[2]}
                    </Grid>
                    <Grid item xs={4}>
                        {historyImages[2]}
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container>
                    <Grid item xs={4}>
                        {historyImages[3]}
                    </Grid>
                    <Grid item xs={4}>
                        {historyTexts[3]}
                    </Grid>
                    <Grid item xs={4}>
                        {historyImages[4]}
                    </Grid>
                </Grid>
                <Divider/>
                {historyTexts[4]}
                {historyImages[5]}
                <Divider/>
                {historyTexts[5]}
                {historyImages[6]}
            </Container>
        </div>
    )

    const carousel = (
        <Carousel
            responsive={responsive}
            ssr={true}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            keyBoardControl={true}
            className={"carousel"}
        >
            {getTypedItems(2, contents, classes.companyImage).map((item, index) => {
                return (item)
            })}
        </Carousel>
    )

    const counts = (
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} className={classes.cardText}>
                                    <EmojiEventsIcon className={classes.icons}/>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"h6"}
                                                variant={"h6"}>
                                        <CountUp isCounting={isCounting} start={0} end={2} duration={2}
                                                 easing={"easeOutCubic"}/></Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"p"}>Innungssieger</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} className={classes.cardText}>
                                    <HistoryIcon className={classes.icons}/>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"p"}>Seit</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"h6"}
                                                variant={"h6"}>
                                        <CountUp isCounting={isCounting} start={new Date().getFullYear()} end={1873}
                                                 duration={3}/></Typography>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} className={classes.cardText}>
                                    <GroupIcon className={classes.icons}/>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"h6"}
                                                variant={"h6"}>
                                        <CountUp isCounting={isCounting} start={0} end={5} duration={3}/></Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"p"}>Generationen</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} className={classes.cardText}>
                                    <CreateIcon className={classes.icons}/>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"h6"}
                                                variant={"h6"}>
                                        <CountUp isCounting={isCounting} start={0} end={new Date().getFullYear() - 1873}
                                                 duration={3}/></Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.cardText}>
                                    <Typography component={"p"}>Jahre Erfahrung</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    )

    const machinery = (
        <div>
            <div className={classes.machineryText}>
                {getTypedItems(6, contents).map((item) => {
                    return (item)
                })}
            </div>
            <Grid container spacing={3}>
                {getTypedItems(7, contents, classes.machineryImage).map((item) => {
                    return (<Grid item md={4}>
                        <Card className={classes.card}>
                            <CardContent>
                                <CardActionArea>
                                    {item}
                                </CardActionArea>
                            </CardContent>
                        </Card>
                    </Grid>)
                })}
            </Grid>
        </div>
    );


    return (<div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Über uns - Schreinerei Wiest</title>
            <meta
                name="description"
                content="Über uns und unsere Historie"
            />
        </Helmet>
        <div className={classes.carouselDiv}>
            {carousel}
            <Plx
                tagName='div'
                className={classes.plx}
                parallaxData={textData}
            >
                {/*<div className={classes.headerTitle}>*/}
                {getTypedItems(1, contents, classes.headerTitle).map((item) => {
                    return (item)
                })}
                {/*</div>*/}
            </Plx>
        </div>
        <div className={classes.company}>
            <Container>
                <Plx
                    key={"cards-items"}
                    parallaxData={plxData.data}
                    onPlxEnd={() => {
                        setIsCounting(true)
                    }}
                >
                    {counts}
                    {company}
                </Plx>
            </Container>
        </div>
        <div>
            <Container>
                {machinery}
            </Container>
        </div>
        <div className={classes.history}>
            {history}
        </div>
    </div>)
}
