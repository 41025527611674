import {Container} from "@material-ui/core";

export default function Datenschutz() {
    return (
        <Container>
            <h1>Datenschutzerklärung</h1>
            <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
                ist:</p>
            <p>Max Wiest
                <br/>Ringstraße 12
                <br/>89281 Altenstadt</p>
            <h2>Ihre Betroffenenrechte</h2>
            <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
                ausüben:</p>
            <ul>
                <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch
                    nicht löschen dürfen (Art. 18 DSGVO),
                </li>
                <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit
                    uns abgeschlossen haben (Art. 20 DSGVO).
                </li>
            </ul>
            <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
                widerrufen.</p>
            <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
                Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
                zuständige Behörde.</p>
            <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
                rel="nofollow noopener noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
            </p>
            <p></p><h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig
                Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese
                Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem,
                den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
            <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
            <ul>
                <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zur Optimierung unserer Website.</li>
            </ul>
            <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art
                werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die
                dahinterstehende Technik zu optimieren. </p>
            <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
            <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
                der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
            <h3>Empfänger:</h3>
            <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
                Webseite als Auftragsverarbeiter tätig werden.</p>
            <p></p><h3>Speicherdauer:</h3>
            <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
                für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige
                Sitzung beendet ist. </p>
            <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine
                darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
                anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
            <p></p><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
                vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website
                nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.
                Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
            <p></p><h2>Verwendung von Google Maps</h2>
            <p>Auf dieser Website nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir
                Ihnen interaktive Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung
                der Karten-Funktion.<br/>Nähere Informationen über die Datenverarbeitung durch Google können Sie den
                Google-Datenschutzhinweisen entnehmen: <a href="https://policies.google.com/privacy"
                                                          rel="noopener nofollow noreferrer"
                                                          target="_blank">https://policies.google.com/privacy</a>. Dort
                können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.</p>
            <p>Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie
                hier: <a href="https://www.dataliberation.org/" rel="noopener nofollow noreferrer"
                         target="_blank">https://www.dataliberation.org</a></p>
            <p>Durch den Besuch der Website erhält Google Informationen, dass Sie die entsprechende Unterseite unserer
                Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über
                das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
                Ihre Daten direkt Ihrem Konto zugeordnet. </p>
            <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des
                Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke
                der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Websites. Eine solche Auswertung
                erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und
                um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen
                steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung
                dessen an Google richten müssen. </p>
            <h3>Widerruf der Einwilligung:</h3>
            <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
                Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website
                verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende
                Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall
                können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>
            <p></p><h2>SSL-Verschlüsselung</h2>
            <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der
                Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
            <p></p>
            <hr/>
            <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
            <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
            <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
                (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies
                gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
            <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn,
                wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
                und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen.</p>
            <h3>Empfänger eines Widerspruchs</h3>
            <p>Max Wiest
                <br/>Ringstraße 12
                <br/>89281 Altenstadt</p>
            <hr/>
            <h2>Änderung unserer Datenschutzbestimmungen</h2>
            <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
                Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
                z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue
                Datenschutzerklärung.</p>
            <h2>Fragen an den Datenschutzbeauftragten</h2>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an
                die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
            <p>info@moebelschreinerei-wiest.de</p>
            <p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a
                href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener noreferrer">externe
                Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>

        </Container>
    )
}
