import {Container, Divider, Grid} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {BASE_URL, Content, ItemEntry} from "./admin/Admin";
import axios from "axios";
import {getGroupedGroups, getTypedItems} from "../utils/ContentsHelper";
import {getLoadingScreen, useStyles as AppUseStyles} from "../App";
import {Helmet} from "react-helmet";

export type PressContents = {
    contents: ItemEntry[][],
    contentsNoPrio: ItemEntry[][],
}

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        textAlign: "center",
        margin: theme.spacing(3)
    },
    image: {
        width: "100%",
        objectFit: "cover",
    },
    description: {
        textAlign: "center",
        margin: theme.spacing(5)
    }
}));

export default function Press(pressContents: PressContents) {
    const classes = useStyles();
    const {contents, contentsNoPrio} = pressContents;

    const appClasses = AppUseStyles()
    const loadingScreen = getLoadingScreen(appClasses)

    if (contents.length <= 0) {
        return (<div>
            {loadingScreen}
        </div>)
    }

    const headers: JSX.Element[] = getTypedItems(1, contents, classes.header).map((item, index) => {
        return (item)
    })

    const images: JSX.Element[] = getTypedItems(2, contents, classes.image).map((item, index) => {
        return (item)
    })

    const description: JSX.Element[] = getTypedItems(3, contents, classes.description).map((item, index) => {
        return (item)
    })

    const imageDescription: JSX.Element[] = getTypedItems(4, contents).map((item, index) => {
        return (item)
    })

    let count = headers.length;
    if (images.length > headers.length) count = images.length;
    if (description.length > images.length) count = description.length;
    if (imageDescription.length > description.length) count = imageDescription.length;

    const pressItems = [];

    for (let i = 0; i < count; i++) {
        if (i < headers.length)
            pressItems.push(
                headers[i]
            )
        pressItems.push(
            <Grid container>
                <Grid item md={6}>
                    <figure>
                        {i < images.length ? images[i] : <></>}
                        <figcaption>{i < imageDescription.length ? imageDescription[i] : <></>}</figcaption>
                    </figure>
                </Grid>
                <Grid item md={6}>
                    {i < description.length ? description[i] : <></>}
                </Grid>
            </Grid>
        )
        pressItems.push(<Divider/>)
    }

    return (<div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Presse - Schreinerei Wiest</title>
            <meta
                name="description"
                content="Wir in der Presse"
            />
        </Helmet>
        <Container>
            {pressItems}
        </Container>
    </div>)
}
