import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import "../css/fonts.css"
// @ts-ignore
import Plx from 'react-plx';
import {
    Checkbox,
    Divider, Grid, List, ListItem, ListItemText, Paper,
} from "@material-ui/core";
import {BASE_URL, Content, ItemEntry} from "./admin/Admin";
import {getGroupedGroups, getTypedItems} from "../utils/ContentsHelper"
import {getLoadingScreen, useStyles as AppUseStyles} from "../App"
import {Container} from "@material-ui/core";
import HomeStepper from "../templates/Stepper";
import Cards from "../templates/Cards";
import entspanntModernisieren from "../images/Home/entspannt-modernisieren.webp"
import {ListItemIcon} from "@material-ui/core";
import contentContentBackgroundImage from "../images/Home/background.webp"
import contentContentBackgroundImageReverse from "../images/Home/background.webp"
import {Parallax} from 'react-parallax';
import axios from "axios";
import curvyLines from "../images/Home/productCurvyLines.webp";
import {Helmet} from "react-helmet";

export type HomeContents = {
    contents: ItemEntry[][],
    contentsNoPrio: ItemEntry[][],
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            overflow: "hidden",
            position: "relative",
            width: "100%"
        },
        carouselDiv: {
            // overflowX: "visible",
            padding: 0,
            top: 0,
            width: "100%",
            position: "absolute"
        },
        content: {
            marginTop: "105vh",
            position: "relative",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto"
        },
        contentContent: {
            marginTop: "10vh",
        },
        headerTitle: {
            // fontWeight: "bold",
            // color: "#aa3517",
            color: theme.palette.secondary.main,
            fontFamily: "HeaderFont",
            // color: theme.palette.secondary.main,
            padding: theme.spacing(1),
            [theme.breakpoints.up("xs")]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(0),
            },
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            border: "4px solid #aa3517",
            backgroundColor: "rgba(51, 51, 51, 0.5)",
            wordWrap: "break-word",
            [theme.breakpoints.up("sm")]: {
                display: "inline",
            },
            opacity: 1,
        },
        headerSubTitle: {
            fontWeight: "bold",
            // fontSize: "2em",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            color: theme.palette.secondary.main,
            opacity: 1
        },
        header2Title: {
            // fontWeight: "bold",
            // fontSize: "4em"
            // fontFamily: "HeaderFont",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            color: theme.palette.primary.main,
            opacity: 1,
        },
        header2SubTitle: {
            // fontWeight: "bold",
            // fontSize: "2em",
            // fontFamily: "HeaderFont",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            color: theme.palette.primary.main,
            opacity: 1
        },
        frontImage: {
            objectFit: "cover",
            width: "100%",
            height: "97vh",
        },
        plx: {
            position: "absolute",
            top: "40vh",
            textAlign: "center",
            overflow: "hidden",
            width: "100vw"
        },
        titleHide: {
            opacity: 0,
            transition: `opacity 0.5s ease-out`
        },
        titleShow: {
            opacity: 1,
            transition: `opacity 0.5s ease-in`
        },
        cardsDiv: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(3),
        },
        cards: {
            marginTop: theme.spacing(3),
        },
        card: {
            margin: theme.spacing(1),
            height: "100%",
        },
        cardContent: {
            width: "100%",
            overflow: "hidden",
            objectFit: "cover"
        },
        primaryBackground: {
            marginTop: "10vh",
            paddingBottom: "10vh",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            paddingTop: "5vh"
        },
        darkSidePadding: {
            paddingTop: theme.spacing(3),
            width: "100%"
        },
        entspanntModernisieren: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%"
        },
        wasWirBietenHeader: {
            marginTop: "5vh",
            marginBottom: "5vh"
        },
        wasWirBietenListPaper: {
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `url(${contentContentBackgroundImageReverse})`,
            // backgroundImage: `url(${wood})`,
            // backgroundPosition: "center center",
            color: theme.palette.secondary.main,
        },
        wasWirBietenListItem: {
            textAlign: "center",
            borderTop: `1px solid ${theme.palette.primary.main}`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            align: "center"
        },
        checkbox: {
            '&&:hover': {
                backgroundColor: 'transparent',
            },
        },
        zeichnungImage: {
            width: "100%"
        },
        bold: {
            fontWeight: "bold",
        },
        curvyLines: {
            // backgroundImage: `url(${curvyLines})`,
            // backgroundPosition: "center bottom",
            // backgroundRepeat: "no-repeat"
        }
    }),
);

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    }
};

const textData = [
    {
        start: 0,
        startOffset: '0vh',
        duration: '25vh',
        properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            }
        ],
    },
    {
        start: 0,
        startOffset: '0vh',
        duration: '100vh',
        properties: [
            {
                startValue: 0,
                endValue: -50,
                unit: 'vh',
                property: 'translateY',
            },
        ],
    },
];

export default function Home(homeContents: HomeContents) {
    const classes = useStyles();
    const {contents, contentsNoPrio} = homeContents;

    const appClasses = AppUseStyles()
    const loadingScreen = getLoadingScreen(appClasses)
    const [showTitleHeading, setShowTitleHeading] = useState(true);

    if (contents.length <= 0) {
        return (<div>
            {loadingScreen}
        </div>)
    }

    const carousel = (
        <Carousel
            responsive={responsive}
            ssr={true}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            keyBoardControl={true}
            className={"carousel"}
            autoPlay={true}
            focusOnSelect={true}
            autoPlaySpeed={3000}
            customTransition="transform 1000ms ease-in-out"
            afterChange={(previousSlide, state) => {
                console.log("CurrentSlide: " + previousSlide + " | " + state.totalItems)
                if (state.currentSlide === state.totalItems - 3) {
                    setShowTitleHeading(true);
                } else {
                    setShowTitleHeading(false);
                }
            }}
        >
            {getTypedItems(1, contentsNoPrio, classes.frontImage).map((item, index) => {
                return (<div key={"gallery-noprio-" + index}>{item}</div>)
            })}
            {getTypedItems(1, contents, classes.frontImage).map((item, index) => {
                return (<div key={"gallery-" + index}>{item}</div>)
            })}
        </Carousel>
    )

    const stepper = (
        <Container>
            <HomeStepper items={contents}/>
        </Container>
    )

    const titleHeading = (
        <Plx
            tagName='div'
            className={classes.plx}
            parallaxData={textData}
            show={showTitleHeading}
        >
            {/*<div className={classes.headerTitle}>*/}
            {getTypedItems(15, contents, classes.headerTitle).map((item) => {
                return (item)
            })}
            {/*</div>*/}
            {/*<div className={classes.headerSubTitle}>*/}
            {/*    {getTypedItems(3, contents).map((item) => {*/}
            {/*        return (item)*/}
            {/*    })}*/}
            {/*</div>*/}
        </Plx>
    )

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Home - Schreinerei Wiest</title>
                <meta
                    name="description"
                    content="Handwerkliche Tradition perfekt auf Ihren Lifestyle abgestimmt"
                />
            </Helmet>
            <div className={classes.page}>
                <div className={classes.carouselDiv}>
                    {carousel}
                    {
                        showTitleHeading ? (
                            <div className={classes.titleShow}>
                                {titleHeading}
                            </div>
                        ) : <div className={classes.titleHide}>{titleHeading}</div>
                    }

                </div>
                <div className={classes.content}>
                    <div className={classes.contentContent}>
                        <Container>
                            <Parallax bgImage={contentContentBackgroundImage} strength={250}>
                                <Divider/>
                                <Container className={classes.header2Title}>
                                    {getTypedItems(2, contents, classes.header2Title).map((item) => {
                                        return (item)
                                    })}
                                </Container>
                                <Container className={classes.header2SubTitle}>
                                    {getTypedItems(3, contents, classes.header2SubTitle).map((item) => {
                                        return (item)
                                    })}
                                </Container>
                                <Divider/>
                            </Parallax>
                        </Container>
                    </div>
                    <div className={classes.cardsDiv} id={"cardsDiv"}>
                        <Container>
                            {getTypedItems(8, contents).map((item) => {
                                return (item)
                            })}
                            <div className={classes.cards}>
                                <Cards items={contents}/>
                            </div>
                        </Container>
                    </div>
                    <div className={classes.curvyLines}>
                        <div>
                            <Container>
                                {getTypedItems(14, contents, classes.zeichnungImage).map((item) => {
                                    return (item)
                                })}
                            </Container>
                        </div>
                        <Container className={classes.darkSidePadding}>
                            {getTypedItems(10, contents).map((item) => {
                                return (item)
                            })}
                        </Container>
                    </div>
                    <div className={classes.primaryBackground}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item md={1} className={classes.darkSidePadding}>
                                            <img className={classes.entspanntModernisieren}
                                                 src={entspanntModernisieren}
                                                 alt={"Entspannt modernisieren"}/>
                                        </Grid>
                                        <Grid item md={11} className={classes.darkSidePadding}>
                                            {getTypedItems(11, contents).map((item) => {
                                                return (item)
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {getTypedItems(13, contents, classes.wasWirBietenHeader).map((item) => {
                                return (item)
                            })}
                            <Container>
                                <Paper className={classes.wasWirBietenListPaper}>
                                    <List>
                                        {getTypedItems(12, contents, classes.bold).map((item, index) => {
                                            return (
                                                <ListItem key={"was-wir-bieten-" + index}
                                                          className={classes.wasWirBietenListItem}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            className={classes.checkbox}
                                                            checked={true}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item}/>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Paper>
                                {stepper}
                            </Container>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}
