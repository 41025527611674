import AdminHeader from "./Header";
import axios from "axios";
import React, {useEffect} from 'react';
import {useCookies} from "react-cookie";
import Login, {LoginType} from "./Login"

export type Content = {
    content: ItemEntry[],
    types: ContentType[],
    typeUrl: string
    baseUrl: string
}

export type ContentType = {
    id: number,
    name: string
}

export type ContentTypes = {
    types: ContentType[],
}

export type ItemEntry = {
    id: number,
    name: string,
    content: string,
    group_id: number,
    type: number,
    group_order: number,
    priority: boolean
}

// export const BASE_URL = "http://localhost:3200"
export const BASE_URL = "https://backend.moebelschreinerei-wiest.de"

export default function Admin() {
    const [tokenCookie] = useCookies(['token']);
    const [loggedIn, setLoggedIn] = React.useState<boolean>(false);

    // Run only once
    useEffect(() => {
        checkLoggedIn();
    }, [])

    const checkLoggedIn = () => {
        axios.get<LoginType>(BASE_URL + "/accounts/isloggedin", {
            headers: {
                'Authorization': 'Bearer ' + tokenCookie.token
            }
        }).then(result => {
            console.log(result)
            if (result.status === 200)
                setLoggedIn(true);
            else setLoggedIn(false);
        }).catch(reason => {
            setLoggedIn(false);
        })
    }

    if (!loggedIn) {
        return (<Login/>)
    } else {
        return (
            <div>
                <AdminHeader/>
            </div>)
    }
}
